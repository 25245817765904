import React from 'react';
import { PublicClientApplication, AccountInfo } from "@azure/msal-browser";
import { UserType } from "../store/system/types";
import { UserInfo } from '../api/types/auth-login-request';

export interface IAuthContext {
    getUserAccount(): AccountInfo | null;
    getUserName(): string | undefined;
    getUserType(): UserType;
    getEmployeeId(): string;
    getUserRoles(): string[]
}

export type UserNameType = string | undefined;

export type AuthContextProviderProps = {
    children?: React.ReactElement;
    msal: PublicClientApplication;
    userInfo: UserInfo
};

export type TokenClaims = {
    roles: string[]
};

const AuthContext = React.createContext<IAuthContext>(null!);

const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children, msal, userInfo }) => {
    const getUserAccount = (): AccountInfo | null => {
        if (userInfo.userType === UserType.Agent) {
            let account = msal.getActiveAccount();
            return account;
        }
        return null;
    };

    const getUserName = (): string | undefined => {
        return userInfo.userType === UserType.Agent ? userInfo.userName : 'LPCustomer';
    };

    const getEmployeeId = (): string => {
        return userInfo.userType === UserType.Agent ? userInfo.employeeId : 'LPCustomer';
    };

    const getUserType = (): UserType => {
        if (userInfo.userType in UserType) {
            return userInfo.userType as UserType;
        }
        return UserType.Customer;
    }

    const getUserRoles = (): string[] | [] => {
        return userInfo.userRoles ?? []
    };

    return (<AuthContext.Provider value={{ getUserAccount: getUserAccount, getUserType: getUserType, getUserName: getUserName, getEmployeeId: getEmployeeId, getUserRoles: getUserRoles }}>
        {children}
    </AuthContext.Provider>);
};

export { AuthContextProvider, AuthContext }

export const useAuthContext = (): IAuthContext => {
    return React.useContext(AuthContext);
}